.schedule-container {
  .form-group .wide-input {
    @include mediaQuery(tiny-phone) {
      width: 100%;
    }
  }

  .schedule-warehouse-select-container {
    max-width: 30rem;

    @include mediaQuery(phone) {
      max-width: 180px;
    }
  }
}

.schedule-header {
  position: sticky;
}

@include mediaQuery(tab-land) {
  // Dla urządzeń <= tab-land musimy umożliwić scrollowanie harmonogramu w poziomie
  // dlatego nie możemy używać już sticky schedule-header
  .schedule-navigation {
    z-index: 101 !important;
  }
  .schedule-body-container {
    overflow-x: scroll;
  }
  .schedule-header {
    top: 0 !important;
  }
}
