.users-table-container {
  .container {
    @include mediaQuery(tab-land) {
      width: 100%;
    }
  }

  .row .pull-right {
    @include mediaQuery(tiny-phone) {
      float: left !important;
    }
  }
}
