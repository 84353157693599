.step-header {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
  padding-left: 1rem;

  .center-vertically {
    line-height: 3.5rem;
  }

  .group {
    display: flex;

    &-space-between {
      justify-content: space-between;
    }

    &-left {
      justify-content: flex-start;

      button:first-child {
        margin-right: .5rem;
      }

      @include mediaQuery(tab-land) {
        button:first-child {
          margin-right: 0;
        }

        button {
          margin-bottom: .5rem;
        }
      }
    }

    @include mediaQuery(tab-land) {
      flex-direction: column;
    }
  }
}
