.custom-field-options-table {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 4rem 0;
  margin: 4rem 0;

  .table {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 0;

      .col {
        flex-basis: 0;
        margin: .5rem;

        &-0 {
          flex-grow: 0;
        }

        &-1 {
          flex-grow: 1;
        }

        &-2 {
          flex-grow: 2;
        }

        &.description {
          padding-left: .15rem;
          font-weight: bold;
        }
      }
    }
  }

  span[class^="ember-power-select-"] {
    line-height: 3.2rem;
  }
}
