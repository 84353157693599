.index-table {

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    .col {
      margin: .1rem;
      flex-basis: 0;
      &-0 {
        flex-grow: 0;
      }
      &-1 {
        flex-grow: 1;
      }
      &-2 {
        flex-grow: 2;
      }
      &-12 {
        flex-grow: 12;
      }
    }
  }
}

.vertical-align {
  display: flex;
  align-items: center;
}
