div[data-field-name='warehouses-table'] {
  .table-responsive {
    @include mediaQuery(phone) {
      th {
        white-space: normal !important;
      }
    }
  }
}

div.table-responsive:not([data-field-name='warehouses-table']),
table.table-responsive {
  td {
    vertical-align: middle;
    @include mediaQuery(phone) {
      white-space: normal !important;
    }
  }
}
