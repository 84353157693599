.transport-types-to-excel {
  width: 50rem;

  & .title {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  & .description {
    font-weight: normal;
  }
}
