.transport-modal {
  .table-nav.col-xs-6,
  .table-nav.col-xs-5 {
    @include mediaQuery(tiny-phone) {
      width: 100%;

      .btn-toolbar {
        float: none !important;
        margin-top: 5px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .changePageSize {
    min-width: 64px;
    display: block;
  }
}
