.company-list {
  .responsive-table-header {
    @include mediaQuery(designer-choice) {
      margin-top: 45px;
    }
  }

  .models-table-wrapper {
    width: 100%;
    overflow: auto;
  }

  .table-footer {
    @include mediaQuery(tiny-phone) {
      .col-xs-1 {
        width: 25%;
      }

      .table-nav {
        width: 100%;
      }
    }
  }
}
