.free-subsequent-spots {
  table {
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0 .8rem;
  }

  .create-advice-btn {
    float: right;
  }

  .btn-sm {
    padding: 0.3rem;
  }

  .show-search-spots-btn {
    margin-bottom: 2rem;
  }
}
