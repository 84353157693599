// Klasa opisująca okienko z informacjami („Wybierz wolne miejsce...”) w trakcie tworzenia awizacji w harmonogramie
.schedule-transport-alert {
  @include mediaQuery(tab-port) {
    display: none;
  }
}

.schedule-transport-alert-mobile {
  display: none;
  @include mediaQuery(tab-port) {
    display: block;
    margin-bottom: 0;
    transition: margin-bottom 1s ease-in-out;
    h4 {
      padding: 0;
    }

    hr {
      padding: 0;
      margin: 0;
    }

    .close-alert {
      float: left;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
