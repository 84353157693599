@import "../abstract/variables";

#auth-login {
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  &-ad {
    @extend #auth-login;
    background: #07789b;
    border-color: #07789b;
  }
}

#auth-remind {
  cursor: pointer;
  color: white;
  text-decoration: none !important;
  font-weight: bold;
  :hover {
    color: inherit;
  }
}

#auth-or {
  margin: 15px 10px;
  text-transform: uppercase;
}

.transparent-background {
  background: rgba(0, 24, 24, 0.6);
  color: white;
  border-radius: 5px;
  padding-bottom: 10px;

  .ember-power-select-selected-item, .ember-power-select-placeholder {
    color: $textColor;
    text-shadow: none;
  }

  a {
    text-shadow: none;
  }

  h1, h2, h3, h4, h5 {
    padding: 1rem;
  }

  .btn-demo {
    width: 100%;
    margin-top: 3px;
    white-space: normal;
  }

  &.alert-danger {
    color: $alertColor;
    background: #f2dede;
  }
}

.divider {
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  &:before, &:after {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }
}
