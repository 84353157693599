div.transport-table-nav {
  z-index: 100;

  > div:last-child > div {

    .small-padding {
      padding: 0;
    }

    @include mediaQuery(phone) {
      float: left !important;
      .form-control {
        display: inline-block;
      }
      .small-padding {
        padding: 4px;
      }
    }

    @include mediaQuery(tiny-phone) {
      label[for='archive-type-mode-select'] {
        float: left;
      }
      .form-control {
        display: block;
      }
    }
  }

  @include mediaQuery(phone) {
    .form-group {
      display: inline-block;
    }
  }
}

div.transport-tables-container {

  @include mediaQuery(tiny-phone) {
    .pull-right {
      float: right !important;
    }
  }

  .table-responsive {
    min-height: .01%;
    overflow-x: visible;
  }
}
