.mode-of-transportation {

  .red-warning {
    color: $redColor;
  }

  .yellow-warning {
    color: $warningColor;
  }
}
