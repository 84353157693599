.ember-power-select-group[aria-disabled="true"] .ember-power-select-option,
.ember-power-select-option[aria-disabled="true"] {
  pointer-events: auto !important;
}

label[for='terms-of-condition'] {
  white-space: normal;
  text-align: left !important;
}

@include mediaQuery(phone) {
  div[data-field-name='terms-of-condition'] {
    display: flex;
    align-items: flex-start;
  }
  label[for='terms-of-condition'] {
    padding-right: 15px;
  }
  button[data-button-name='cancel'] {
    white-space: normal;
  }
}
