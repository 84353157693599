/*

Wzorowane na projekcie Bogdana --> https://bitbucket.org/bogdan_markiewka/scss-example/src/master/.
Analogiczne rozwiązanie znaleźć można też np. na https://gist.github.com/reciosonny/5950ec906d25592eed6dd20bd47371f4.

Uwagi:
- zwykle dla telefonu są ustawione rozmiary 0 - 600 px, ale nasz projektant stosuje 768 px;
- dodaliśmy jeden poziom dla małych telefonów, ponieważ projektant używa w swoich stylach bardzo szczegółowych ustawień.

0 - 440px       Small phone
440 - 768px     Phone
768 - 900px     Tablet portrait
900 - 1200px    Tablet landscape
1024 - 1800px   Normal styles
1800px+         Big desktop

$breakpoints:
- phone
- tab-port
- standard
- big-desktop

1em = 16px
*/

@mixin mediaQuery($breakpoint) {
  @if $breakpoint == tiny-phone { // <= 440px
    @media only screen and (max-width: 27.5em) {
      @content
    };
  }
  @if $breakpoint == phone { // <= 768px
    @media only screen and (max-width: 48em) {
      @content
    };
  }
  @if $breakpoint == tab-port { // <= 900px
    @media only screen and (max-width: 56.25em) {
      @content
    };
  }
  @if $breakpoint == tab-land { // <= 1024px (tablet w trybie landscape)
    @media only screen and (max-width: 64em) {
      @content
    };
  }
  // Dziwne, bardzo precyzyjne ustawienie wybrane przez naszego projektanta w kilku miejscach
  @if $breakpoint == designer-choice {
    @media only screen and (min-width: 1201px) and (max-width: 1215px) {
      @content
    };
  }
  @if $breakpoint == big-desktop { // > 1800
    @media only screen and (min-width: 112.5em) {
      @content
    };
  }
}
